import React from "react"
import { Link } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import styled from "styled-components"

import Layout from "../components/layout"
import footerBg from "../images/footer-bg.png"

const Div = styled.div`
  background-image: url(${footerBg});
  margin: 0px auto;
  width: 95%;
  margin-top: 50px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .cover {
    width: 37%;
    min-width: 200px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      background: yellow;
      left: -20px;
      top: -20px;
      z-index: -1;
    }
  }
  .content {
    padding: 20px;
    width: 70%;
    min-width: 320px;
  }
  h1 {
    font-family: Asap;
    font-size: 3em;
    font-weight: bold;
  }
  p {
    text-align: justify;
    font-size: 1em;
    line-height: 30px;
    margin: 20px 0px;
  }
`

const AboutPage = () => (
  <Div id="about">
    <div className="cover">
      <img src={require("../images/members.jpg")} />
    </div>
    <div className="content">
      <h1>About Rokka Group</h1>
      <p>
        “ROKKA GROUP” consists of different industries involved in timber
        business, wood seasoning, forest products, furniture, herbal products,
        Organic Product, Kattha, Rosin & Turpentine, Housing & Land Development,
        Government supply, Project Base works etc. Mr. Basanta Rokka Chhetri,
        the chairperson, had started the venture for more than 45 years and as a
        result there are about 15 “Fifteen” companies & industries under the
        same umbrella, ROKKA GROUP. The various sister concerns of the group are
        being operated at different places of the country. Since the timber
        business is increasing at an alarming rate, we are planning to extend
        our business to a new height with expansion of our existing capacity.
        The demand for timber depends on the number of houses constructed and
        repaired over time. It also depends on the amount of furniture used per
        household or per building. As the population increases over time, new
        households are formed; they require new housing and public
        infrastructures such as schools, banks, government offices etc. Timber
        is an important material for house or building construction. The
        increased interest and preference towards natural products both
        domestically and internationally have given opportunity for us to
        develop products from woods and forest products. The government of Nepal
        is also promoting the use of ayurvedic medicines; presently the
        medicines are mostly imported from India. Washing bags made from ritha,
        handmade papers products, handicrafts made from allo and bamboos,
        Kattha, Rosin & Turpentine and most major items now Organic Herbal
        Essential Oil Essential Oils are some examples of final products
        exported from Nepal. Recently, there is an increasing interest and
        investment in herbal products including herbal soap manufacturing in
        Nepal. ROKKA GROUP with its sister concerns is helping the nation as a
        whole by increasing the production of timber products, herbal products
        and manufacturing furniture and fixtures with in the country and even
        exporting the products. ROKKA GROUP has started PPP (Private Public
        Partnership Program) In current situation for the sustainable resource
        of raw material. framing, plantation, cultivation and own self owned
        nursery, Therefore own requirement can be full field by own production.
        And also this program help the people who are living in below poverty
        line. By implementation of this PPPP program poverty people can build
        their living standard, education, health and basic requirements of their
        daily needs. And the ROKKA GROUP can build their source of raw material
        for sustainable growth and to get succeed in future plans and vision.
      </p>
    </div>
  </Div>
)

export default AboutPage
